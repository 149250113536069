// font setup
/* exo-2-200 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Exo 2 ExtraLight'), local('Exo2-ExtraLight'),
       url('/assets/fonts/exo-2-v7-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/exo-2-v7-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-300 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Exo 2 Light'), local('Exo2-Light'),
       url('/assets/fonts/exo-2-v7-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/exo-2-v7-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-regular - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Exo 2 Regular'), local('Exo2-Regular'),
       url('/assets/fonts/exo-2-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/exo-2-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}